import React from 'react';
import env from "../../../envVariables";

export default function Footer() {
  const version = env.REACT_APP_VERSION;

  return (
    <div id="footer">
      <div className="text-center">
        INSi Consult version
        {' '}
        {version}
        . Copyright 2000-2180, icanopée. Tous droits réservés
        <br />
        <a href="https://www.icanopee.fr/politique-de-confidentialite/" target="_blank" rel="noopener noreferrer">
          Mentions légales & politique de confidentialité
        </a>
        {' - '}
        <a
          href="https://www.icanopee.fr/solutions/produit-efficience/assistance-efficience/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Support client
        </a>
      </div>
    </div>
  );
}
