import * as yup from 'yup';
import '../../utils/formUtils';
import { extractDate } from '../index';
import { OIDS } from '../../constants';
import { shortSexes } from '../../constants/dmpConstants';
import env from '../../../envVariables';

export const validationSchema = yup.object({
  ins: yup.string().DMPCMaxLength(13).required('Ce champs est requis'),
  key: yup.string().DMPCMaxLength(2).required('Ce champs est requis'),
  name: yup.string().INSiNames().DMPCMaxLength(80).required('Ce champs est requis'),
  given: yup.string().INSiNames().DMPCMaxLength(60).required('Ce champs est requis'),
  birthday: yup.string().date().pastDate().required('Ce champs est requis'),
  birthplace: yup.string().DMPCMaxLength(5),
  sex: yup.number().oneOf([2, 3]),
});

export const formatValues = (values) => {
  const {
    name, given, birthday, birthplace, sex, ins, key, nia,
  } = values;
  const onlyDigitRegex = new RegExp('^[0-9]*$');

  // ne pas envoyer les lieux de naissance contenant des lettres en prod
  const birthPlace = (onlyDigitRegex.test(birthplace) || Number(env.REACT_APP_PRODUCTON_MODE) === 0) && birthplace !== '99999'
    ? birthplace
    : '';

  return {
    s_ins: ins,
    s_key: key,
    s_birthName: name.toUpperCase().replace(/\s+/g, ' ').replace(/-+/g, '-').trim(),
    s_given: given.toUpperCase().replace(/\s+/g, ' ').replace(/-+/g, '-').trim(),
    i_sex: Number(sex),
    s_birthDate: extractDate(birthday, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    s_birthPlace: birthPlace,
    s_oid: nia === true ? OIDS.NIA : OIDS.NORMAL,
  };
};

export const formatExport = (values, isValid, error) => {
  const {
    name, given, birthday, birthplace, sex, ins, key, nia, exportId,
  } = values;

  let obj = {
    exportId,
    Ins: {
      nir: ins,
      key,
      oid: nia === true ? OIDS.NIA : OIDS.NORMAL,
    },
    name,
    given,
    sex: shortSexes[sex],
    birthDate: extractDate(birthday, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    birthPlace: birthplace,
    identityIsValid: isValid,
  };

  if (error) {
    obj = {
      ...obj,
      error: error.s_apiErrorExtendedInformations,
    };
  }

  return obj;
};

export const formatExportJson = (values, isValid, error) => {
  const obj = formatExport(values, isValid, error);

  return JSON.stringify(obj);
};

export const initialValues = {
  ins: '',
  key: '',
  name: '',
  given: '',
  birthday: '',
  birthplace: '',
  sex: 1,
  nia: false,
};
